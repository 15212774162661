import { useState, useEffect } from 'react';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import Thumbnails from 'components/thumbnails';
import Spinner from 'components/spinner';
import './index.scss';
import Swal from 'sweetalert2';

const Viewer = ({ thumb1, thumb2, thumb3, thumb4, thumb5, thumb6, thumbBlur, status, duration, authenticated, subscription, handleCheckboxChange, selectedImages, isLegalAge, setIsLegalAge, isImgClicked, setIsImgClicked }) => {
  const [mainUrl, setMainUrl] = useState('');

  /* eslint-disable */
  useEffect(()=>{
    setIsImgClicked(false);
  }, [mainUrl]);
  /* eslint-enable */

  const checkImageBlur = () => {
    if(isImgClicked) return false;
    if ((mainUrl === 'thumb1' && thumbBlur[0])
      || (mainUrl === 'thumb2' && thumbBlur[1])
      || (mainUrl === 'thumb3' && thumbBlur[2])
      || (mainUrl === 'thumb4' && thumbBlur[3])
      || (mainUrl === 'thumb5' && thumbBlur[4])
      || (mainUrl === 'thumb6' && thumbBlur[5])
      || (!mainUrl && thumbBlur[0])
    ) {
      return true;
    } else {
      return false;
    }
  }

  const getImgSrc = () => {
    if (mainUrl === 'thumb1') {
      return thumb1;
    } else if (mainUrl === 'thumb2') {
      return thumb2;
    } else if (mainUrl === 'thumb3') {
      return thumb3;
    } else if (mainUrl === 'thumb4') {
      return thumb4;
    } else if (mainUrl === 'thumb5') {
      return thumb5;
    } else if (mainUrl === 'thumb6') {
      return thumb6;
    }
    return thumb1;
  };

  const upgradePopup = () => {
    window.btutil_modalRegisterUpgrade();
  };

  const handleBlur = (confirmAge = true) => {
    if(!isLegalAge && confirmAge) {
      Swal.fire({
        title: "Confirmation Required",
        html: "Please confirm that you are 18 years old or above.",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        confirmButtonColor: "#16a34a"
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLegalAge(true);
          handleBlur(false);
        }
      });
      return;
    }

    setIsImgClicked(isImgClicked ? false : true);
  }

  return (
    <div className="generated-images-viewer">
      <div className="time-counter">
        Images generated using
        <br />
        Stability AI in {duration ? duration.toFixed(2) : '0'} seconds
      </div>
      <div className="image-generate-container">
        <div className="generator-header">
          <div className={cx('thumbnails-main-container', { blur: checkImageBlur() })}
          onClick={handleBlur}>
            {
              getImgSrc() ? <>
                <img draggable="false" className="main-image" src={getImgSrc()} alt="Full Size"></img>
                <div class="view-img"><i class="fa fa-eye" aria-hidden="true"></i> View Image</div>
              </> : <>
                <Spinner></Spinner>
                <div className='thumbnails-image'></div>
              </>
            }
          </div>
          <div className="thumbnails-container">

            <div className={cx('thumbnail', 'thumb1', { blur : thumbBlur[1 - 1] })}
              onClick={() => {
                setMainUrl('thumb1')
                const checkboxRef = document.querySelector('input[value="thumb1"]');
                if (checkboxRef) checkboxRef.click();
              }
            }>
              {
                thumb1 ? <>
                  <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb1');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb1"
                    checked={selectedImages.includes('thumb1')}
                  />
                  <img draggable="false" className="thumbnail" src={thumb1} alt="Thumb1"/>
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>

            <div className={cx('thumbnail', 'thumb2', { blur : thumbBlur[2 - 1] })}
              onClick={() => {
                setMainUrl('thumb2')
                const checkboxRef = document.querySelector('input[value="thumb2"]');
                if (checkboxRef) checkboxRef.click();
              }
            }>
              {
                thumb2 ? <>
                  <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb2');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb2"
                    checked={selectedImages.includes('thumb2')}
                  />
                  <img draggable="false" className="thumbnail" src={thumb2} alt="Thumb2"/>
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>

            <div className={cx('thumbnail thumb3', { locked: !subscription && thumb3 }, { blur: thumbBlur[3 - 1] })}
              onClick={subscription ? () => {
                setMainUrl('thumb3');
                const checkboxRef = document.querySelector('input[value="thumb3"]');
                if (checkboxRef) checkboxRef.click();
                } : thumb3 ? upgradePopup : undefined}>
              {
                thumb3 ? <>
                  {subscription && <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb3');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb3"
                    checked={subscription ? selectedImages.includes('thumb3') : false}
                  /> }
                  <img draggable="false" className="thumbnail" src={thumb3} alt="Thumb3"/>
                  {!subscription && <FontAwesomeIcon icon={faLock} />}
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>

            <div className={cx('thumbnail thumb4', { locked: !subscription && thumb4 }, { blur: thumbBlur[4 - 1] })}
              onClick={subscription ? () => {
                setMainUrl('thumb4');
                const checkboxRef = document.querySelector('input[value="thumb4"]');
                if (checkboxRef) checkboxRef.click();
                } : thumb4 ? upgradePopup : undefined}>
              {
                thumb4 ? <>
                  {subscription && <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb4');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb4"
                    checked={subscription ? selectedImages.includes('thumb4') : false}
                  /> }
                  <img draggable="false" className="thumbnail" src={thumb4} alt="Thumb4"/>
                  {!subscription && <FontAwesomeIcon icon={faLock} />}
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>

            <div className={cx('thumbnail thumb5', { locked: !subscription && thumb5 }, { blur: thumbBlur[5 - 1] })}
              onClick={subscription ? () => {
                setMainUrl('thumb5');
                const checkboxRef = document.querySelector('input[value="thumb5"]');
                if (checkboxRef) checkboxRef.click();
                } : thumb5 ? upgradePopup : undefined}>
              {
                thumb5 ? <>
                  {subscription && <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb5');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb5"
                    checked={subscription ? selectedImages.includes('thumb5') : false}
                  /> }
                  <img draggable="false" className="thumbnail" src={thumb5} alt="Thumb5"/>
                  {!subscription && <FontAwesomeIcon icon={faLock} />}
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>

            <div className={cx('thumbnail thumb6', { locked: !subscription && thumb6 }, { blur: thumbBlur[6 - 1] })}
              onClick={subscription ? () => {
                setMainUrl('thumb6');
                const checkboxRef = document.querySelector('input[value="thumb6"]');
                if (checkboxRef) checkboxRef.click();
                } : thumb6 ? upgradePopup : undefined}>
              {
                thumb6 ? <>
                  {subscription && <input type="checkbox"
                    onChange={handleCheckboxChange}
                    onClick={() => {
                      const thumbRef = document.querySelector('.thumb6');
                      if (thumbRef) thumbRef.click();
                    }}
                    value="thumb6"
                    checked={subscription ? selectedImages.includes('thumb6') : false}
                  /> }
                  <img draggable="false" className="thumbnail" src={thumb6} alt="Thumb6"/>
                  {!subscription && <FontAwesomeIcon icon={faLock} />}
                </> : <>
                  <Spinner></Spinner>
                  <div className='thumbnails-image'></div>
                </>
              }
            </div>
          </div>
          <Thumbnails
            thumb1={thumb1}
            thumb2={thumb2}
            thumb3={thumb3}
            thumb4={thumb4}
            thumb5={thumb5}
            thumb6={thumb6}
            thumbBlur={thumbBlur}
            status={status}
            authenticated={authenticated}
            subscription={subscription}
            setMainUrl={setMainUrl}
            selectedImages={selectedImages}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>
        {(authenticated || subscription) && (
          <div className="tag-container">
            <input type="text" placeholder="#tags" />
            <div className="tag-button">
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Viewer;
